import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FeatureActions = createActionGroup({
    source: 'User',
    events: {
        persistUserAuth: props<{ token: string }>(),
        persistUserAuthSuccess: emptyProps(),
        purgeUserAuth: emptyProps(),
        purgeUserAuthSuccess: emptyProps(),
    },
});
