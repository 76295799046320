import { inject } from '@angular/core';
import { Actions, createEffect, FunctionalEffect, ofType } from '@ngrx/effects';
import { iif, map, of, switchMap, tap } from 'rxjs';

import { AUTH_MANAGER, AUTH_NAVIGATOR } from '@portal/core/utils';

import { FeatureActions } from './feature.actions';

const initialize: FunctionalEffect = createEffect(
    () =>
        inject(AUTH_MANAGER)
            .initializeAuthenticationToken()
            .pipe(
                switchMap((token: string | null) =>
                    iif(() => token === null, of(FeatureActions.purgeUserAuth()), of(FeatureActions.persistUserAuth({ token: token! })))
                )
            ),
    { functional: true }
);

const persistUserAuth: FunctionalEffect = createEffect(
    (actions = inject(Actions), authManager = inject(AUTH_MANAGER)) =>
        actions.pipe(
            ofType(FeatureActions.persistUserAuth.type),
            tap(({ token }: ReturnType<typeof FeatureActions.persistUserAuth>) => {
                authManager.setAuthorizationToken(token);
            }),
            map(({ token }: ReturnType<typeof FeatureActions.persistUserAuth>) => FeatureActions.persistUserAuthSuccess())
        ),
    { functional: true }
);

const persistUserAuthSuccess: FunctionalEffect = createEffect(
    (actions = inject(Actions), authNavigator = inject(AUTH_NAVIGATOR)) =>
        actions.pipe(
            ofType(FeatureActions.persistUserAuthSuccess.type),
            tap(() => {
                void authNavigator.loginNavigate();
            })
        ),
    { functional: true, dispatch: false }
);

const purgeUserAuth: FunctionalEffect = createEffect(
    (actions = inject(Actions), authManager = inject(AUTH_MANAGER)) =>
        actions.pipe(
            ofType(FeatureActions.purgeUserAuth.type),
            tap(() => {
                authManager.resetAuthorizationToken();
            }),
            map(() => FeatureActions.purgeUserAuthSuccess())
        ),
    { functional: true }
);

const purgeUserAuthSuccess: FunctionalEffect = createEffect(
    (actions = inject(Actions), authNavigator = inject(AUTH_NAVIGATOR)) =>
        actions.pipe(
            ofType(FeatureActions.purgeUserAuthSuccess.type),
            tap(() => {
                void authNavigator.logoutNavigate();
            })
        ),
    { functional: true, dispatch: false }
);

export const FeatureEffects = {
    initialize,
    persistUserAuth,
    persistUserAuthSuccess,
    purgeUserAuth,
    purgeUserAuthSuccess,
};
