import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FeatureActions } from './feature.actions';

@Injectable()
export class FeatureFacade {
    private readonly store = inject(Store);

    public userLogin(token: string): void {
        this.store.dispatch(FeatureActions.persistUserAuth({ token }));
    }

    public userLogout(): void {
        this.store.dispatch(FeatureActions.purgeUserAuth());
    }
}
